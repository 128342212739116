import { Headline, Highlight, P } from 'ui-kit/typography';
import { nodesOf, storeType } from 'helpers/prop-types';

import Accordion from 'ui-kit/accordion';
import Button from 'ui-kit/button';
import Card from 'ui-kit/card';
import Container from 'ui-kit/container';
import Image from 'ui-kit/image';
import Intro from 'ui-kit/intro';
import Layout from '../components/layout';
import List from 'ui-kit/list';
import Observable from 'ui-kit/observable';
import PropTypes from 'prop-types';
import React from 'react';
import Section from 'ui-kit/section';
import Stage from 'ui-kit/stage';
import StickyNav from 'ui-kit/sticky-nav';
import Storelocator from 'ui-kit/storelocator';
import TableH2 from '../components/table-h2';
import Textlink from 'ui-kit/textlink';
import Umweltpdf from '../downloads/Umwelterklaerung-BF-2021_Online.pdf';
import { getImageFromNode } from 'helpers/utils';
import { graphql } from 'gatsby';

const OriginAndQualityPage = ({ data }) => {
    /** Bildsammlungen */
    const { contentImages, gqDetailImages } = data;

    /** Einzelbilder */
    const stageImage = data.stageImage.childImageSharp;
    const producerPanoramaImage = data.producerPanoramaImage.childImageSharp;

    /** Inhalte für das Akkordion */
    const accordionItems = [
        {
            title: 'Geprüfte Qualität Bayern (GQ)',
            isOpen: true,
            subtitle: 'Garantiert bayerische Herkunft und Herstellung sowie hohe Qualität.',
            content: (
                <>
                    <Container size="l" grid layout="5-7" cols="2" alignItems="center">
                        <Image
                            {...getImageFromNode(
                                contentImages,
                                'origin-and-quality-page/inhaltsbild-gepruefte-qualitaet.jpg'
                            ).node.childImageSharp}
                            layer={1}
                            alt="Siegel für Geprüfte Qualität Bayern"
                            gap={{ small: 'xxl', medium: 'none' }}
                        />
                        <div>
                            <Headline level="h3">
                                Sicherheit mit Prädikat: Geprüfte Qualität Bayern
                            </Headline>
                            <P gap="xl">
                                Das GQ-Siegel steht für das vom Bayerischen Staatsministerium für
                                Ernährung, Landwirtschaft und Forsten entwickelte
                                stufenübergreifende Qualitäts- und Herkunftssicherungsprogramm für
                                regionale Lebensmittel. Daran erkennen Sie auf einen Blick die
                                bayerische Herkunft und Herstellung sowie den höchsten
                                Qualitätsstandard der ausgezeichneten Produkte.
                            </P>
                            <Button size="s" layer={1} linkTo="https://www.gq-bayern.de/">
                                Mehr zum GQ-Siegel
                            </Button>
                        </div>
                    </Container>
                    <hr />
                    <Container size="l" grid cols="4">
                        <Card
                            tag="Rindfleisch"
                            link="https://www.gq-bayern.de/produkte-und-hersteller/produktsortiment/#1505047779681-2112b3a5-6b55"
                            image={
                                getImageFromNode(
                                    gqDetailImages,
                                    'origin-and-quality-page/gepruefte-qualitaet-detail-rind.jpg'
                                ).node.childImageSharp
                            }
                            imageAlt="Glückliche Weidekuh"
                            gap={{ small: 'xxxl', medium: 'none' }}
                        >
                            <P fontSize="s">
                                Rindfleischprodukte mit GQ-Siegel stammen von ausgewählten
                                bayerischen Lieferanten und werden streng kontrolliert.
                            </P>
                            <Highlight fontSize="s">Mehr erfahren</Highlight>
                        </Card>
                        <Card
                            tag="Schweinefleisch"
                            link="https://www.gq-bayern.de/produkte-und-hersteller/produktsortiment/#1505047848370-35a21f5d-3938"
                            image={
                                getImageFromNode(
                                    gqDetailImages,
                                    'origin-and-quality-page/gepruefte-qualitaet-detail-schwein.jpg'
                                ).node.childImageSharp
                            }
                            imageAlt="Glückliches Schwein beim Grasen"
                            gap={{ small: 'xxxl', medium: 'none' }}
                        >
                            <P fontSize="s">
                                Auch bei Schweinefleisch beweist das GQ-Siegel die bayerische
                                Herkunft und Verarbeitung sowie die geprüfte Qualität.
                            </P>
                            <Highlight fontSize="s">Mehr erfahren</Highlight>
                        </Card>
                        <Card
                            tag="Wurst"
                            link="https://www.gq-bayern.de/produktsortiment"
                            image={
                                getImageFromNode(
                                    gqDetailImages,
                                    'origin-and-quality-page/gepruefte-qualitaet-detail-wurst.jpg'
                                ).node.childImageSharp
                            }
                            imageAlt="Appetitlich angerichtete Wurstwaren"
                            gap={{ small: 'xxxl', medium: 'none' }}
                        >
                            <P fontSize="s">
                                Wurst mit dem GQ-Siegel wird ausschließlich in Bayern aus
                                bayerischem Rind- Schweine- oder Geflügelfleisch hergestellt.
                            </P>
                            <Highlight fontSize="s">Mehr erfahren</Highlight>
                        </Card>
                        <Card
                            tag="Putenfleisch"
                            link="https://www.gq-bayern.de/produkte-und-hersteller/produktsortiment/#1505047546107-9c366968-436f"
                            image={
                                getImageFromNode(
                                    gqDetailImages,
                                    'origin-and-quality-page/gepruefte-qualitaet-detail-pute.jpg'
                                ).node.childImageSharp
                            }
                            imageAlt="Zwei glückliche Truthähne"
                            gap={{ small: 'xxxl', medium: 'none' }}
                        >
                            <P fontSize="s">
                                Bei Puten und Putenfleisch belegt das GQ-Siegel die 100 % bayerische
                                Herkunft und Verarbeitung sowie kontrollierte Qualität.
                            </P>
                            <Highlight fontSize="s">Mehr erfahren</Highlight>
                        </Card>
                    </Container>
                </>
            ),
        },
        {
            title: 'QS-Siegel',
            subtitle: 'Zeichen für lückenlose, wirksame Qualitätssicherung',
            content: (
                <>
                    <Container size="l" grid layout="5-7" cols="2" alignItems="center">
                        <Image
                            {...getImageFromNode(
                                contentImages,
                                'origin-and-quality-page/inhaltsbild-qs-logo.jpg'
                            ).node.childImageSharp}
                            layer={1}
                            alt="QS Logo"
                            gap={{ small: 'xxl', medium: 'none' }}
                        />
                        <div>
                            <P>
                                Das QS-System ist ein seit 2001 bewährtes prozess- und
                                unternehmensübergreifendes Qualitätssicherungssystem für
                                Lebensmittel. Es umfasst sämtliche Stufen des Produktions- und
                                Vermarktungsprozesses. Bei Fleisch- und Wurstwaren zählen dazu die
                                Futtermittelherstellung, die landwirtschaftliche Erzeugung, die
                                Schlachtung, Zerlegung und Verarbeitung sowie die Vermarktung. Jeder
                                dieser Schritte ist in das System integriert und wird sorgfältig und
                                präzise überdacht. Damit gewährleistet das QS-Prüfsystem eine
                                durchgängige, effektive Qualitätssicherung – vom Landwirt bis zur
                                Ladentheke.
                            </P>
                        </div>
                    </Container>
                </>
            ),
        },
    ];

    return (
        <Layout description="Herkunft und Qualität">
            <Stage
                image={stageImage}
                tag="Herkunft & Qualität"
                headline={{ text: 'Genießen Sie mit gutem Gewissen', level: 'h1' }}
                imageAlt="Landwirt, der ein Kalb auf dem Arm hält"
                bottomShade
            />
            <Section>
                <Intro title="Vertrauen Sie auf klare Herkunft und geprüfte Qualität">
                    <P fontSize="m">
                        Unsere Fleisch- und Wurstspezialitäten versprechen nachhaltigen Genuss. So
                        kommt unser Rind- und Schweinefleisch ausschließlich aus Bayern – und trägt
                        das Siegel „Geprüfte Qualität Bayern“. Alle unsere Produkte entsprechen den
                        strengen Kriterien des International Food Standards IFS und lassen sich
                        lückenlos rückverfolgen.
                    </P>
                    <P fontSize="m">
                        Handwerkliche Herstellungsverfahren kombiniert mit modernen Technologien,
                        die wir kontinuierlich verbessern, sorgen für nachhaltige Produktion.
                        Sämtliche Produktionsprozesse und Produkte erfüllen alle Prüfbestimmungen
                        hinsichtlich Qualität, Hygiene und Herkunft. Die Auslieferung erfolgt unter
                        strengster Einhaltung der Kühlkette.
                    </P>
                    <Highlight>Darauf können Sie sich verlassen!</Highlight>
                </Intro>
            </Section>
            <StickyNav
                items={[
                    {
                        id: 'auszeichnungen',
                        text: 'Auszeichnungen',
                        to: '#auszeichnungen',
                    },
                    {
                        id: 'nachhaltigkeit',
                        text: 'Nachhaltigkeit',
                        to: '#nachhaltigkeit',
                    },
                    {
                        id: 'edeka-suedbayern-h2',
                        text: 'EDEKA Südbayern H2',
                        to: '#edeka-suedbayern-h2',
                    },
                    {
                        id: 'bauernsuche',
                        text: 'Bauernsuche',
                        to: '#bauernsuche',
                    },
                ]}
            >
                {({ setActivePageFragment }) => (
                    <>
                        <Section bg="gray" container="l" id="auszeichnungen">
                            <Observable callback={() => setActivePageFragment('auszeichnungen')}>
                                <Headline level="h2" textAlign="center" gap="l">
                                    Ausgezeichnet – Qualität mit Brief und Siegel
                                </Headline>
                                <Accordion items={accordionItems} gap="xl" scrollToOffset={80} />
                                <P textAlign="center">
                                    Weiter Prüfsiegel im Überblick und Informationen zu den
                                    wichtigsten Kennzeichnungen und ihrer Bedeutung finden Sie{' '}
                                    <Textlink
                                        fontSize="s"
                                        to="https://www.edeka.de/unsere-marken/edeka-qualitaetsversprechen/pruefsiegel/siegel-uebersicht.jsp"
                                    >
                                        hier.
                                    </Textlink>
                                </P>
                            </Observable>
                        </Section>
                        <Section id="nachhaltigkeit">
                            <Observable callback={() => setActivePageFragment('nachhaltigkeit')}>
                                <Intro
                                    title={
                                        <span
                                            /* eslint-disable-next-line */
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    'Qualität, Regionalität und Transparenz <br /> – alles für nachhaltigen Genuss',
                                            }}
                                        />
                                    }
                                    gap="xxxl"
                                >
                                    Mit unserer großen Vielfalt beliebter Fleisch- und Wurstwaren
                                    erfüllen wir die unterschiedlichsten Wünsche und höchsten
                                    Ansprüche unserer Kunden – und garantieren stets beste, geprüfte
                                    Qualität. Hohe Qualitätsmaßstäbe gelten für die Produkte, wie
                                    auch für alle Abläufe rund um unser Unternehmen.
                                    Verantwortungsbewusstes, nachhaltiges Handeln hat dabei immer
                                    oberste Priorität.
                                </Intro>
                                <Container size="l" grid layout="6-6" cols="2" alignItems="start">
                                    <Image
                                        {...getImageFromNode(
                                            contentImages,
                                            'origin-and-quality-page/inhaltsbild-nachhaltigkeit.jpg'
                                        ).node.childImageSharp}
                                        alt="Sonnenblume vor blauem Himmel"
                                        layer={1}
                                        gap={{ small: 'xxl', medium: 'none' }}
                                    />
                                    <div>
                                        <Headline level="h3">
                                            Nachhaltiges Handeln erfordert Weitsicht und Respekt
                                        </Headline>
                                        <P>
                                            Dazu gehört eine respektvolle Behandlung von Mensch,
                                            Tier und Umwelt. Wir schonen die natürlichen Ressourcen
                                            und legen großen Wert auf ein faires,
                                            partnerschaftliches Miteinander. Sowohl innerhalb des
                                            Unternehmens als auch im täglichen Umgang mit unseren
                                            Lieferanten und den Verbrauchern. Als modernes,
                                            transparentes Unternehmen stehen wir in ständigem
                                            Austausch mit wichtigen Institutionen wie Ministerien,
                                            Behörden sowie Verbraucherverbänden und pflegen den
                                            offenen Dialog mit unseren Kunden.
                                        </P>
                                        <P>
                                            Verantwortungsbewusstes Handeln bedeutet für uns auch,
                                            dass wir, soweit dies möglich ist, auf den Einsatz von
                                            Allergenen verzichten. So sorgen wir für
                                            Geschmackserlebnisse, die Sie guten Gewissens genießen
                                            können.
                                        </P>
                                        <Button linkTo={Umweltpdf}>
                                            Umwelterklärung Bayernfleisch
                                        </Button>
                                    </div>
                                </Container>
                            </Observable>
                        </Section>
                        <Section bg="gray" container="l" id="edeka-suedbayern-h2">
                            <Observable
                                callback={() => setActivePageFragment('edeka-suedbayern-h2')}
                            >
                                <Intro
                                    title="Gutes von daheim – von Bauern aus unserer Region"
                                    gap="l"
                                >
                                    <Headline level="h2" textAlign="center" gap="l">
                                        Pflicht- und Prüfkriterien zur Anerkennung in der
                                        Haltungsform 2 „StallhaltungPlus“
                                    </Headline>
                                    <P textAlign="center" gap="xl">
                                        Für das Programm “EDEKA Südbayern H2” sind weiterhin
                                        nachfolgende Prüfkriterien im Bereich Schweinemast zu
                                        erfüllen.
                                    </P>
                                    <Container size="l">
                                        <Container
                                            size="l"
                                            grid
                                            layout="6-6"
                                            cols="2"
                                            alignItems="start"
                                            gap="xl"
                                        >
                                            <Image
                                                {...getImageFromNode(
                                                    contentImages,
                                                    'origin-and-quality-page/inhaltsbild-bayern-quality.jpg'
                                                ).node.childImageSharp}
                                                layer={1}
                                                alt="Siegel für Geprüfte Qualität Bayern"
                                                gap={{ small: 'xxl', medium: 'none' }}
                                            />
                                            <Image
                                                {...getImageFromNode(
                                                    contentImages,
                                                    'origin-and-quality-page/inhaltsbild-tierwohl.jpg'
                                                ).node.childImageSharp}
                                                layer={1}
                                                alt="Siegel für Geprüfte Qualität Bayern"
                                                gap={{ small: 'xxl', medium: 'none' }}
                                            />
                                        </Container>
                                        <Headline level="h3" gap="md" textAlign="center">
                                            Regionalität
                                        </Headline>
                                        <List
                                            gap="l"
                                            items={[
                                                'Es besteht eine gültige Zulassung im Bereich Schweinemast für “Qualität und Sicherheit (QS)”.',
                                                'Es besteht eine gültige Zulassung im Bereich Schweinemast für “Initiative Tierwohl (ITW)”.',
                                                'Es besteht eine gültige Zulassung im Bereich Schweinemast für “Geprüfte Qualität - Bayern (GQ)”.',
                                            ]}
                                        />

                                        <Headline level="h3" gap="md" textAlign="center">
                                            Haltung
                                        </Headline>
                                        <List
                                            gap="l"
                                            items={[
                                                'Den Tieren wird mind. 10 % mehr Fläche als gesetzlich vorgeschrieben angeboten.',
                                            ]}
                                        />

                                        <TableH2 />

                                        <Headline level="h3" gap="md" textAlign="center">
                                            Tiergesundheit und Tierwohl
                                        </Headline>
                                        <List
                                            gap="l"
                                            textAlign="center"
                                            items={[
                                                'Teilnahme am QS-Salmonellenmonitoring',
                                                'Teilnahme am QS-Antibiotikamonitoring',
                                                'Teilnahme am indexierten Schlachtbefunddatenprogramm: die Befunddatenerfassung am Schlachthof erfolgt gemäß QS-Leitfaden Befunddaten in der Schweineschlachtung.',
                                                'Die Transportdauer ab der letzten Beladung bis zum Schlachthof beträgt max. vier Stunden.',
                                            ]}
                                        />
                                    </Container>
                                </Intro>
                            </Observable>
                        </Section>
                        <Section bg="gray" container="l" smallPaddingBottom id="bauernsuche">
                            <Observable callback={() => setActivePageFragment('bauernsuche')}>
                                <Container size="l">
                                    <Intro
                                        title="Gutes von daheim – von Bauern aus unserer Region"
                                        gap="l"
                                    >
                                        <Image
                                            {...producerPanoramaImage}
                                            layer={1}
                                            gap="l"
                                            alt="Landwirt mit Kühen auf der Weide vor bayerischer Alpenlandschaft"
                                        />
                                        <P fontSize="m" gap="l">
                                            Um Qualität nachhaltig zu erzeugen, dauerhaft
                                            sicherzustellen und gleichzeitig die bayerischen
                                            Landwirte zu stärken, setzen wir auf heimische, uns
                                            bekannte Lieferanten. Unser Fleisch beziehen wir auf
                                            kurzen Wegen, nach Möglichkeit von regionalen
                                            Erzeugergemeinschaften wie zum Beispiel:
                                        </P>
                                        <List
                                            items={[
                                                <Textlink
                                                    to="https://www.egmiesbach.de/"
                                                    key="https://www.egmiesbach.de/"
                                                    gap="m"
                                                    display="block"
                                                >
                                                    Erzeugergemeinschaft Miesbach w.V.
                                                </Textlink>,
                                                <Textlink
                                                    to="https://www.eg-traunstein.de/"
                                                    key="https://www.eg-traunstein.de/"
                                                    gap="m"
                                                    display="block"
                                                >
                                                    Erzeugergemeinschaft Schlachtvieh w.V.
                                                    Traunstein
                                                </Textlink>,
                                                <Highlight gap="m" fontSize="s">
                                                    Erzeugergemeinschaft Oberbayern für tierische
                                                    Veredelung w.V.
                                                </Highlight>,
                                                <Textlink
                                                    to="https://eg-suedbayern.de/"
                                                    key="https://eg-suedbayern.de/"
                                                    gap="m"
                                                    display="block"
                                                >
                                                    Erzeugergemeinschaft Südostbayern
                                                </Textlink>,
                                            ]}
                                        />
                                    </Intro>
                                    <Headline level="h3" textAlign="center" gap="none">
                                        Hier finden Sie Bauern, die uns beliefern
                                    </Headline>
                                </Container>
                            </Observable>
                        </Section>
                    </>
                )}
            </StickyNav>

            <Container>
                <Storelocator
                    stores={data.allFarmersJson.edges.map(({ node }) => node)}
                    formHeadline="Bauernsuche"
                />{' '}
            </Container>
        </Layout>
    );
};

OriginAndQualityPage.propTypes = {
    data: PropTypes.shape({
        stageImage: PropTypes.object,
        allFarmersJson: nodesOf(storeType),
        contentImages: PropTypes.object,
        gqDetailImages: PropTypes.object,
        producerPanoramaImage: PropTypes.object,
    }).isRequired,
};

export const query = graphql`
    query {
        stageImage: file(
            relativePath: { eq: "origin-and-quality-page/buehne-herkunft-und-qualitaet.jpg" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        contentImages: allFile(
            filter: { relativePath: { glob: "origin-and-quality-page/inhaltsbild-*.jpg" } }
        ) {
            edges {
                node {
                    relativePath
                    childImageSharp {
                        fluid(maxWidth: 605, quality: 80) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
        gqDetailImages: allFile(
            filter: {
                relativePath: { glob: "origin-and-quality-page/gepruefte-qualitaet-detail-*.jpg" }
            }
        ) {
            edges {
                node {
                    relativePath
                    childImageSharp {
                        fluid(maxWidth: 575, quality: 80) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
        producerPanoramaImage: file(
            relativePath: { eq: "origin-and-quality-page/panorama-erzeugergemeinschaften.jpg" }
        ) {
            childImageSharp {
                fluid(maxWidth: 850, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        allFarmersJson {
            edges {
                node {
                    id
                    zip
                    city
                    lat
                    lng
                }
            }
        }
    }
`;

export default OriginAndQualityPage;
