import React from 'react';
import styled from 'styled-components';

const Table1 = () => {
    const Table = styled.div`
        text-align: left;
        border: 1px solid #004487;
        border-radius: 0;
        overflow: hidden;
        width: 100%;
        background-color: white;
        margin-bottom: 30px;
    `;

    const ColType1 = styled.p`
        margin: 0;
        flex: 0 0 31%;
    `;

    const ColType2 = styled.p`
        margin: 0;
        flex: 0 0 23%;
    `;

    const Tr = styled.div`
        display: flex;
        justify-content: space-between;
        padding: 10px;
    `;

    const Styledtr = styled(Tr)`
        background: #004487;
        color: white;
        font-weight: 700;
    `;

    const Trpadding = styled(Tr)`
        background: #d1d4eb;
    `;

    return (
        <Table>
            <Styledtr>
                <ColType1>Durchschnittsgewicht</ColType1>
                <ColType2>Bodenfläche je Tier gesetzlich</ColType2>
                <ColType2>10% mehr Bodenfläche</ColType2>
                <ColType2>Bodenfläche je Tier im Programm</ColType2>
            </Styledtr>
            <Tr>
                <ColType1>über 30 - 50 kg</ColType1>
                <ColType2>0,50 m²</ColType2>
                <ColType1>0,05 m²</ColType1>
                <ColType2>0,55 m²</ColType2>
            </Tr>
            <Trpadding>
                <ColType1>über 50 – 100 kg</ColType1>
                <ColType2>0,75 m²</ColType2>
                <ColType1>0,075 m²</ColType1>
                <ColType2>0,825 m²</ColType2>
            </Trpadding>
            <Tr>
                <ColType1>über 110 kg</ColType1>
                <ColType2>1,00 m²</ColType2>
                <ColType1>0,10 m²</ColType1>
                <ColType2>1,10 m²</ColType2>
            </Tr>
        </Table>
    );
};

export default Table1;
